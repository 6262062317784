import { combineReducers } from 'redux'
import app from './app'
import member from './member'
import setting from './setting'
import history from './history'
const rootReducer = combineReducers({
  member,
  app,
  setting,
  history
})

export default rootReducer